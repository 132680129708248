<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<h3 class="mt-4 mb-4">{{ $t('pax.croisement_avec') }} {{ stallion_selected.horse_nom }} X {{ mare.horse_nom }}</h3>
			<button href="#" @click="goBack()" class="btn btn-primary btn-prev mb-2"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /> {{ $t('pax.retour') }}</button>
			<div class="recapGraphique mb-4">
				<div class="resultGraphique mb-4 d-none">
					{{ $t('pax.morphologie') }} {{ groupe_moyenne.morphologie }}<br/>
					{{ $t('pax.allure_saut') }} {{ groupe_moyenne.allure_saut }}<br/>
					{{ $t('pax.autres') }} {{ groupe_moyenne.autre }}<br/>
					{{ $t('pax.compat_totale') }} {{ Math.round(((groupe_moyenne.morphologie + groupe_moyenne.allure_saut + groupe_moyenne.autre) / 3) * 100) / 100 }}
					<a href="#" @click.prevent=""><span>{{ $t('pax.affiner_analyse') }}<i>{{ $t('pax.encore') }} {{criteres_restant}} {{ $t('pax.criteres_a_evaluer') }}</i></span><font-awesome-icon class="ml-auto" :icon="['fal', 'long-arrow-right']" /></a>
				</div>
				<div class="row">
					<div class="col-6">
						<a href="" class="btn btn-primary btn-block d-none">{{ $t('pax.telecharger') }}</a>
					</div>
					<div class="col-6">
						<router-link :to="{ name: 'ContractList' }" class="btn btn-primary btn-block d-none">{{ $t('pax.creer_un_contrat') }}</router-link>
					</div>
				</div>
			</div>	
			<div class="row">

				<div v-for="(critere) in criteres_local" :key="critere.criteres_id" class="col-12 col-md-6 col-xl-4 mb-4" :class="{noprint : (opened_slide.indexOf(critere.criteres_id) > -1 || critere.caracterisations.length < 2)}">
					<div class="critere paxgraphique"  id="myGroup">
						<b>{{ critere.criteres_lang[0].critereslang_label }}</b>
						<div v-if="opened_slide.indexOf(critere.criteres_id) == -1 && critere.caracterisations.length == 2" class="edit_pax_graphique" @click.prevent="open_slider(critere.criteres_id)"><font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon></div>
						
						<div v-if="critere.caracterisations.length == 2" class="position-relative">
							<!-- Img courbe -->
							<img :src="require(`/src/assets/img/pax/legende${(critere.caracterisations[0].caracterisation_valeur + critere.caracterisations[1].caracterisation_valeur) / 2}.jpg`)" :alt="'min_max_' + critere.criteres_min + '_' + critere.criteres_max">
							<!-- Img carré vert -->
							<img :src="require(`/src/assets/img/pax/zone${critere.criteres_min}${critere.criteres_max}.png`)">
							
							<div class="row">
								<div class="col">{{ critere.criteres_lang[0].critereslang_moins }} </div>
								<div class="col text-right">{{ critere.criteres_lang[0].critereslang_plus }}</div>
							</div>
						</div>
						<div class="noncat" v-else>
							{{ $t('pax.critere_non_caracterise') }}
						</div>

						<div v-if="opened_slide.indexOf(critere.criteres_id) > -1 || critere.caracterisations.length < 2">
							<div :ref="'collapse-'+critere.criteres_id" >
								<hr class="mt-0">
								<div class="row">
									<div class="col">
										{{ critere.critereslang_moins }}
									</div>
									<div class="col text-right">
										{{ critere.critereslang_plus }}
									</div>
								</div>
								<input type="range" min="1" max="4" step="0.5" list="tickmarks" class="form-range" @input="changeSlider(critere)" v-model="critere.slider"> 
								<datalist id="tickmarks">
									<option value="1" label="0%"></option>
									<option value="2.5" label="50%"></option>
									<option value="4" label="100%"></option>
								</datalist>
								<div class="marks">
									<div class="row no-gutters">
										<div class="col-auto">1</div>
										<div class="col-auto ml-auto">2</div>
										<div class="col-auto ml-auto">3</div>
										<div class="col-auto ml-auto">4</div>
									</div>
								</div>
								<a href="#" @click.prevent="displayHelp(critere.criteres_id, criteres_lang[0].critereslang_label)" class="my-3"><b>{{ $t('pax.aide') }}</b> <font-awesome-icon :icon="['far', 'info-circle']" /></a>
							</div>

							<div class="text-center mb-2">
								<a class="btn btn-primary btn-outline-primary btn-sm d-inline-block mr-2" href="#" @click.prevent="close_slider(critere.criteres_id)">{{ $t('global.enregistrer') }} <font-awesome-icon :icon="['fal', 'save']" /></a>
								<a class="btn btn-outline-primary btn-sm d-inline-block" href="#" @click.prevent="critere.slider = null; changeSlider(critere); close_slider(critere.criteres_id)">{{ $t('global.effacer') }} <font-awesome-icon :icon="['far', 'eraser']" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import Window from '@/mixins/Window.js'
	import _cloneDeep from 'lodash/cloneDeep';
	import _debounce from 'lodash/debounce'

	export default {
		name: "PaxGraphique",
		props: {
			mare: {
				type: Object,
				default: () => ( null )
			},
			stallion_id: {
				type: Number,
				default: () => ( null )
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			},
			pax_express: {
				type: Boolean,
				default: () => false
			}
		},
		mixins: [Navigation, PaxMixin, Tools, Window],
		data () {
			return {
				addOrEditReady: false,
				debouncedSend: _debounce(this.saveCritereMare, 300),
				stallion_selected: {},
				criteres_restant: 0,
				opened_slide: [],
				refresh_slider: true,
				criteres_local: [],
				criteres_groupe: {
					1: 'morphologie',
					2: 'morphologie',
					3: 'morphologie',
					4: 'morphologie',
					5: 'morphologie',
					6: 'morphologie',
					7: 'morphologie',
					8: 'morphologie',
					9: 'morphologie',
					10: 'morphologie',
					11: 'morphologie',
					12: 'allure_saut',
					13: 'allure_saut',
					14: 'allure_saut',
					15: 'allure_saut',
					16: 'allure_saut',
					17: 'allure_saut',
					18: 'autre',
					19: 'autre',
					20: 'autre',
					21: 'autre'
				},
				groupe_moyenne: {
					'morphologie': 0,
					'allure_saut': 0,
					'autre': 0,
				},
				nb_groupe_moyenne: {
					'morphologie': 0,
					'allure_saut': 0,
					'autre': 0,
				},
				lang: 'fr',
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			
			async init_component() {
				this.addOrEditReady = false
				
				this.lang = this.getConfig('lang')

				
				
				this.criteres_local = await this.getInfosPaxGrahique(this.lang, this.mare.horse_id, this.stallion_id)
				this.criteres_restant = 0
				let tab_criteres_for_compat = []
				let stallion_criteres = {}
				for (let index = 0; index < this.criteres_local.length; index++) {
					const element = this.criteres_local[index];
					this.criteres_local[index].edit_slide = false
					if(element.caracterisations.length != 2) {
						this.criteres_restant ++
					}
					else {
						stallion_criteres[element.criteres_id] = element.caracterisations.find(horse => horse.caracterisation_horse == this.stallion_id).caracterisation_valeur / 100
						this.criteres_local[index].slider = element.caracterisations.find(horse => horse.caracterisation_horse == this.mare.horse_id).caracterisation_valeur / 100
						tab_criteres_for_compat.push(this.criteres_local[index])
						
					}
				}

				let tab_stallion_for_compat = [
					{
						horse_id: this.stallion_id,
						horse_criteres: stallion_criteres
					}
				]

				let recap = await this.paxCalculCompatiblite(tab_stallion_for_compat, tab_criteres_for_compat)

				for (const i in recap[this.stallion_id]){

					const groupe = this.criteres_groupe[i]

					this.nb_groupe_moyenne[groupe]++
					this.groupe_moyenne[groupe] += recap[this.stallion_id][i]
				}

				for (const key in this.groupe_moyenne) {
					this.groupe_moyenne[key] = this.groupe_moyenne[key] / this.nb_groupe_moyenne[key]
				}

				this.stallion_selected = this.stallions.find(elem => elem.horse_id == this.stallion_id)

				this.addOrEditReady = true
			},

			changeSlider(critere) {
				this.refresh_slider = false
				let nb_slider = 0

				this.open_slider(critere.criteres_id)

				for(let index in this.criteres_local) {
					if(this.criteres_local[index].slider) {
						nb_slider++
					}
				}

				if(this.mare.horse_id) {
					this.debouncedSend(critere)
				}
				this.refresh_slider = true
			},

			saveCritereMare(critere) {

				if(critere.caracterisations[1] == undefined) {
					critere.caracterisations.push({
						caracterisation_valeur: (critere.slider * 100)
					})
				}
				else {
					critere.caracterisations[1].caracterisation_valeur = (critere.slider * 100)
				}
				this.saveCaracterisationByHorse(this.mare.horse_id, critere.criteres_id, critere.slider)
			},

			open_slider(criteres_id) {
				const index = this.opened_slide.findIndex(elem => elem == criteres_id)
				if(index == -1) {
					this.opened_slide.push(criteres_id)
				}
			},

			close_slider(criteres_id) {
				const index = this.opened_slide.findIndex(elem => elem == criteres_id)
				if(index > -1) {
					this.opened_slide.splice(index, 1)
				}
			},

			async displayHelp(criteres_id, critere_label) {
				this.title_modal = critere_label
				this.$refs["modal"].show()
				this.modal_loaded = false

				let current_critere = this.criteres_local.find(elem => elem.criteres_id == criteres_id)
				this.content_help = current_critere.critereslang_description
				
				this.modal_loaded = true
			},

			goBack() {
				this.$emit('update:step_label', "comparaison_V")
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
